import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import Image from '../../../components/image'
import CharacterCard from '../../../components/charactercard'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import {CharacterContentWrap, CharacterContentTop, CharacterList, CharacterContentBottom, CharacterImg} from './character-content-area.stc'

const CharacterContentArea = (props) => {
    const characterData = useStaticQuery(graphql `
        query CharacterContentQuery {
            aboutcharacterJson(id: {eq: "character-page-content"}) {
                top_content {
                    heading
                    content {
                        text1
                        text2
                    }
                }
                list_left {
                    heading
                    items
                }
                list_right {
                    heading
                    items
                }
                bottom_content {
                    heading
                }
            }
            allCharacterJson {
                        totalCount
                        nodes {
 
                                id
                                title
                                category
                                desc
                                actor
                                link
                                header
                                tags
                                site
                                image {
                                        childImageSharp {
                                            fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                                                ...GatsbyImageSharpFluid_withWebp
                                                presentationHeight
                                                presentationWidth
                                            }
                                        }
                                    }

                            }
                    
                    }

                    }      
            
    `)

    const characters = characterData.allCharacterJson.nodes
    const {top_content, list_left, list_right, bottom_content} = characterData.aboutcharacterJson

    const {topHeadingCSS, textCSS, listHeadingCSS, bottomHeadingCSS} = props;
    return (
        <CharacterContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        {top_content && (
                            <CharacterContentTop>
                                {top_content.heading && <Heading {...topHeadingCSS}>{top_content.heading}</Heading>}
                                {top_content.content.text1 && <Text {...textCSS}>{top_content.content.text1}</Text>}
                                {top_content.content.text2 && <Text {...textCSS}>{top_content.content.text2}</Text>}
                            </CharacterContentTop>
                        )}
                        <Row>
                            {list_left && (
                                <Col lg={6}>
                                    {list_left.heading && <Heading {...listHeadingCSS}>{list_left.heading}</Heading>}
                                    {list_left.items && (
                                        <CharacterList>
                                            {list_left.items.map((item, i) => <li key={`left-list-${i}`}>{item}</li>)}
                                        </CharacterList>
                                    )}
                                </Col>
                            )}
                            {list_right && (
                                <Col lg={6}>
                                    {list_right.heading && <Heading {...listHeadingCSS}>{list_right.heading}</Heading>}
                                    {list_right.items && (
                                        <CharacterList>
                                            {list_right.items.map((item, i) => <li key={`left-right-${i}`}>{item}</li>)}
                                        </CharacterList>
                                    )}
                                </Col>
                            )}
                        </Row>
                        {bottom_content && (
                            <CharacterContentBottom>
                                {bottom_content.heading && <Heading {...bottomHeadingCSS}>{bottom_content.heading}</Heading>}
                                {characters &&  <CharacterCard characters={characters} />}

                                
                            </CharacterContentBottom>
                        )}
                    </Col>
                </Row>
            </Container>
        </CharacterContentWrap>
    )
}

CharacterContentArea.defaultProps = {
    topHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        lineHeight: 1.375,
        mb: '17px'
    },
    textCSS: {
        mb: '26px'
    },
    listHeadingCSS: {
        as: 'h5',
        mb: '14px'
    },
    bottomHeadingCSS: {
        as: 'h5',
        mb: '24px'
    }
}

export default CharacterContentArea


  