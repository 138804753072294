import React from "react"
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import CharacterTitleArea from '../containers/character/character-title-area'
import CharacterBannerArea from '../containers/character/character-banner-area'
import CharacterContentArea from '../containers/character/character-content-area'
import ArtworkArea from '../containers/global/artwork'
 
const CharacterPage = () => (
  <Layout>
    <SEO 
        title="Character" 
        description="Character Page description"
        keywords={["character", "design", "portfolio"]}
    />
    <Header/>
    <div className="main-content">
        <CharacterTitleArea/>
        <CharacterBannerArea/>
        <CharacterContentArea/>
        <ArtworkArea/>   
    </div>
    <Footer/>
  </Layout>
)

export default CharacterPage
